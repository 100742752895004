import { Component, OnInit, Input, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { OperationalParametric } from '../../models/operationalParametric.model';
import { Observable, of } from '../../../../node_modules/rxjs';
import { ENTER } from '../../../../node_modules/@angular/cdk/keycodes';
import { FormControl } from '../../../../node_modules/@angular/forms';
import { MatAutocomplete, MatChipInputEvent, MatAutocompleteSelectedEvent } from '../../../../node_modules/@angular/material';
import { startWith, map } from '../../../../node_modules/rxjs/operators';

@Component({
  selector: 'app-chip-list-autocomplete',
  templateUrl: './chip-list-autocomplete.component.html',
  styleUrls: ['./chip-list-autocomplete.component.css']
})
export class ChipListAutocompleteComponent implements OnInit, OnChanges {

  @Input() modelo: OperationalParametric;
  @Input() label: string;
  @Input() selectedOptions: any[];
  @Input() options: any;
  @Input() maxSelection = 0;
  @Input() submitted;
  // @Input() obligatorio: boolean;
  @Input() requerido = true;

  @Input() errorState = false;
  @Input() errorHint: string;

  filteredOptions: Observable<any[]>;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  separatorKeysCodes: number[] = [ENTER];

  myControl: FormControl = new FormControl();
  @ViewChild('clienteInput', {static: false}) clienteInput: ElementRef;

  private paisCtrl: FormControl;
  public focused = false;
  toppings = new FormControl();

  // @ViewChild('chipList1', {static: false}) chipList1;

  @ViewChild('fruitInput', {static: false}) fruitInput: ElementRef;
  @ViewChild('auto', {static: false}) matAutocomplete: MatAutocomplete;

  constructor() { }

  ngOnInit() {
    if (!(this.options instanceof Observable)) {
      this.options = of(this.options);
    }
    this.options.toPromise().then(opts => {
      this.filteredOptions = this.toppings.valueChanges.pipe(
                                      startWith(null),
                                      map((opt: string | null) => opt ? this._filter(opt, opts.data) : (opts ? opts.data : null)));

    });
    // this.chipList1.errorState = false;
  }

  ngOnChanges() {
    if (this.submitted) {
      this.toppings.markAsTouched();
    }
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
  }

  remove(option: any): void {
    const index = this.selectedOptions.indexOf(option);
    if (index >= 0) {
      this.selectedOptions.splice(index, 1);
    }
    // if ( this.obligatorio && this.selectedOptions.length < 1) {
    //   this.chipList1.errorState = true;
    // }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedOptions.push(event.option.value);
    this.fruitInput.nativeElement.value = '';
    this.toppings.setValue(null);
  }

  inputClicked() {
    this.fruitInput.nativeElement.blur();
    this.fruitInput.nativeElement.focus();
  }

  public isSelected(opt: any): boolean {
    for (const optSelected of this.selectedOptions) {
      if (opt.id === optSelected.id) {
        return true;
      }
    }
    return false;
  }

  public disable(opt: any): boolean {
    if (this.maxSelection > 0 && this.selectedOptions && this.selectedOptions.length >= this.maxSelection) {
      return true;
    }

    for (const optSelected of this.selectedOptions) {
      if (opt.id === optSelected.id) {
        return true;
      }
    }
    return false;
  }

  private _filter(value: any, options: any[]): any[] {
    const filterValue = value.description ? value.description.toLowerCase() : value.toLowerCase();
    return options.filter(opt => opt.description.toLowerCase().indexOf(filterValue) === 0);
  }

}
