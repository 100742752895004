import { TipoMoneda } from './../../models/tipoMoneda.model';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgForm, FormControl } from '@angular/forms';
import { Producto } from '../../models/producto.model';
import { Imagen } from '../../models/imagen.model';
import { ENTER } from '@angular/cdk/keycodes';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Sugerencia } from '../../models/sugerencia.model';
import { CategoriaProducto } from '../../models/categoria-producto.model';
import { SubcategoriaProducto } from '../../models/subcategoria-producto.model';
import { Cliente } from '../../models/cliente.model';
import { Talle } from '../../models/talle.model';
import { Color } from '../../models/color.model';
import { OperationalParametric } from '../../models/operationalParametric.model';
import { TipoImpresion } from '../../models/tipo-impresion.model';
import { Router, ActivatedRoute } from '@angular/router';
import { NavigationService } from '../../shared-services/navigation.service';
import { SnackService } from '../../shared-services/snack.service';
import { BackendService } from '../../core/backend.service';
import { DialogService } from '../../shared-components/modals/dialog.service';
import { ApiService } from '../../core/api.service';
import { FormValidatorService } from '../../shared-services/form-validator.service';
import { MedidasProducto } from '../../models/medidas-producto.model';
import { DataObject } from '../../models/dataObject.model';
import {Proveedor} from '../../models/proveedor.model';
import { takeUntil } from 'rxjs/operators';
import { Precio } from './../../models/precio.model';


@Component({
  selector: 'app-producto-precios',
  templateUrl: './producto-precios.component.html',
  styleUrls: ['./producto-precios.component.css']
})
export class ProductoPreciosComponent implements OnInit, OnDestroy {

  @ViewChild('form', {static: false}) public productoForm;

  precioVenta: number;
  moneda: TipoMoneda;
  producto: Producto;
  tiposMoneda: OperationalParametric[] = [];

  submitted = false;
  imagen = false;
  private imagenes: Imagen[];
  validationFail = false;
  selected = new FormControl(0);
  separatorKeysCodes: number[] = [ENTER];
  public editorOptions = {
    placeholder: 'Ingrese texto formateado para la descripción técnica'
  };

  sugerencias: Observable<Sugerencia[]>;
  categorias: CategoriaProducto[];
  subcategorias: SubcategoriaProducto[];
  colores: Color[];
  talles: Observable<any>;
  clientes: Cliente[];
  error: boolean;

  categoriasInternas: CategoriaProducto[];
  categoriasExternas: CategoriaProducto[];
  subcategoriasInternas: SubcategoriaProducto[];
  subcategoriasExternas: SubcategoriaProducto[];
  sugerenciasInternas: Sugerencia[];
  sugerenciasExternas: Sugerencia[];

  talleDesde: number;
  talleHasta: number;
  intervalo: number;
  tipoTalle: string;
  tallesNumericos: OperationalParametric[] = [];
  tiposImagenes: OperationalParametric[];
  tiposImpresion: TipoImpresion[];

  listaClientes;

  public categoriaFilterCtrl: FormControl = new FormControl();
  public filteredCategorias: ReplaySubject<CategoriaProducto[]> = new ReplaySubject<CategoriaProducto[]>(1);
  protected _onDestroyCategoria = new Subject<void>();

  public subcategoriaFilterCtrl: FormControl = new FormControl();
  public filteredSubcategorias: ReplaySubject<SubcategoriaProducto[]> = new ReplaySubject<SubcategoriaProducto[]>(1);
  protected _onDestroySubcategoria = new Subject<void>();

  public colorFilterCtrl: FormControl = new FormControl();
  public filteredColores: ReplaySubject<Color[]> = new ReplaySubject<Color[]>(1);
  protected _onDestroyColor = new Subject<void>();

  public entidadFilterCtrl: FormControl = new FormControl();
  public filteredEntidades: ReplaySubject<Cliente[]> = new ReplaySubject<Cliente[]>(1);
  protected _onDestroyEntidad = new Subject<void>();

  categoriasFail = false;
  totalTalles: number;
  productoPropio = 'true';
  ckcontent: any;
  ckEditorConfig: {} =
  {
    toolbar: [
      {name: 'document', items: ['Source']},
      {name: 'edit', items: ['Undo', 'Redo']},
      {
        name: 'basicstyles', items: ['Bold', 'Italic', 'Strike', 'Underline', 'Subscript', 'Superscript',
          '-', 'RemoveFormat', '-',
          'BulletedList', 'NumberedList', 'JustifyLeft', 'JustifyCenter', 'JustifyRight',
          'JustifyBlock']
      },
      {name: 'link', items: ['Link', 'Table', 'SpecialChar']},
      {name: 'algo', items: ['Outdent', 'Indent', 'BidiLtr', 'BidiRtl']},
      {name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize', 'TextColor', ]}
    ],
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private snackService: SnackService,
    private backendService: BackendService,
    private dialogService: DialogService,
    private apiService: ApiService,
    private formValidatorService: FormValidatorService
  ) { }

  ngOnInit() {

    this.getTiposMoneda();

    this.getTalles();
    this.getColores();
    this.getCategorias();
    this.getSubcategorias();
    this.getClientes();
    this.getTiposImagenes();
    this.getTiposImpresion();

    this.route.data.subscribe(data => {
      this.producto = data.user;
      this.producto.activo = this.producto.activo === undefined ? 'true' : this.producto.activo;
      this.producto.categorias = this.producto.categorias ? this.producto.categorias : [];
      this.producto.subcategorias = this.producto.subcategorias ? this.producto.subcategorias : [];
      this.producto.imagenes = this.producto.imagenes ? this.producto.imagenes : [];
      this.producto.colores = this.producto.colores ? this.producto.colores : [];
      this.producto.talles = this.producto.talles ? this.producto.talles : [];
      this.producto.medidas = this.producto.medidas ? this.producto.medidas : new MedidasProducto();
      this.producto.cliente = this.producto.cliente ? this.producto.cliente : new Cliente();
      this.producto.tallesNumericos = this.producto.tallesNumericos ? this.producto.tallesNumericos : [];
      this.producto.cliente = this.producto.cliente ? this.producto.cliente : new Cliente();
      this.productoPropio = this.producto.cliente.id ? 'false' : 'true';

      this.producto.precio = this.producto.precio ? this.producto.precio : new Precio();
      this.producto.precio.tallesNumericos = this.producto.precio.tallesNumericos ? this.producto.precio.tallesNumericos : [];
      this.producto.precio.colores = this.producto.precio.colores ? this.producto.precio.colores : [];

      if (this.producto.precio.adicionalColor) {
        this.producto.precio.adicionalColor = this.producto.precio.adicionalColor * 100;
      }

      if (this.producto.precio.adicionalTalle) {
        this.producto.precio.adicionalTalle = this.producto.precio.adicionalTalle * 100;
      }

      this.producto.precio.tipoMoneda = this.producto.precio.tipoMoneda ? this.producto.precio.tipoMoneda : new OperationalParametric();

      // if (this.producto.tallesNumericos && this.producto.tallesNumericos.length > 0) {
      //   this.tipoTalle = '1';
      // } else if (this.producto.talles &&  this.producto.talles.length > 0) {
      //   this.tipoTalle = '2';
      // } else {
      //   this.tipoTalle = '3';
      // }

    });
    if (this.producto.imagenes.length === 0) {
      this.producto.imagenes.push(new Imagen(undefined));
    }
    if (this.producto.cliente.id === undefined) {
      this.producto.cliente = undefined;
    }

    this.categoriaFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroyCategoria))
    .subscribe(() => {
      this.filter(this.categorias, this.categoriaFilterCtrl, this.filteredCategorias, 'description');
  });
    this.subcategoriaFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroySubcategoria))
    .subscribe(() => {
      this.filter(this.subcategorias, this.subcategoriaFilterCtrl, this.filteredSubcategorias, 'description');
  });
    this.colorFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroyColor))
    .subscribe(() => {
      this.filter(this.colores, this.colorFilterCtrl, this.filteredColores, 'description');
  });
    this.entidadFilterCtrl.valueChanges
    .pipe(takeUntil(this._onDestroyEntidad))
    .subscribe(() => {
      this.filter(this.clientes, this.entidadFilterCtrl, this.filteredEntidades, 'name');
  });
  }

  ngOnDestroy() {
    this._onDestroyCategoria.next();
    this._onDestroyCategoria.complete();
    this._onDestroySubcategoria.next();
    this._onDestroySubcategoria.complete();
    this._onDestroyColor.next();
    this._onDestroyColor.complete();
    this._onDestroyEntidad.next();
    this._onDestroyEntidad.complete();
  }

  protected filter(lista, filterCtrl, filtered, position) {
    if (!lista) {
      return;
    }
    let search = filterCtrl.value;
    if (!search) {
      filtered.next(lista.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    filtered.next(
      lista.filter(value => value[position].toLowerCase().indexOf(search) > -1)
    );
  }

  generarTalles() {
    this.dialogService.confirm('Creación de talles numéricos',
      '¿Está seguro que desea regenerar los talles numéricos, se perderán los talles actuales ?')
      .then( res => {
        if (res) {
          this.tallesNumericos.splice(0);
          this.producto.precio.tallesNumericos.splice(0);
          for (let i = this.talleDesde; i <= this.talleHasta; i += this.intervalo) {
            const t = new OperationalParametric();
            t.id = i;
            t.description = i.toString();
            this.tallesNumericos.push(t);
            this.producto.precio.tallesNumericos.push(t);
          }
        }
      });
  }

  hayDatosExternos() {
    return (this.categoriasExternas && this.categoriasExternas.length > 0) ||
            (this.subcategoriasExternas && this.subcategoriasExternas.length > 0) ||
            (this.sugerenciasExternas && this.sugerenciasExternas.length > 0);
  }

  async getCategorias() {
    await this.backendService.get<DataObject<CategoriaProducto>>(CategoriaProducto.path + 'all', null).toPromise().then(res => {
      this.categorias = res.data;
      this.filteredCategorias.next(this.categorias.slice());
    });
  }
  async getSubcategorias() {
    await this.backendService.get<DataObject<SubcategoriaProducto>>(SubcategoriaProducto.path + 'all', null).toPromise().then(res => {
      this.subcategorias = res.data;
      this.filteredSubcategorias.next(this.subcategorias.slice());
    });
  }
  async getColores() {
    await this.backendService.get<DataObject<Color>>(Color.path + 'all', null).toPromise().then(res => {
      this.colores = res.data;
      this.filteredColores.next(this.colores.slice());
    });
  }
  async getClientes() {
    await this.backendService.get<any>(Cliente.path + 'all/', {}).toPromise().then(res => {
      this.clientes = res;
      this.filteredEntidades.next(this.clientes.slice());
    });
  }
  async getTalles() {
    this.talles = this.backendService.get<any>(Talle.path, null);
    this.talles.subscribe(talles => this.totalTalles = talles.totalElements);
  }
  async getTiposImagenes() {
    await this.apiService.get<DataObject<OperationalParametric>>
      (OperationalParametric.path + 'tipos_imagenes/', null).toPromise().then(res => {
        this.tiposImagenes = res.data;
    });
  }

  async getTiposMoneda() {
    await this.apiService.get<DataObject<OperationalParametric>>
      (OperationalParametric.path + 'tipos_monedas/', null).toPromise().then(res => {
        this.tiposMoneda = res.data;
    });
  }

  async getTiposImpresion() {
    await this.backendService.get<DataObject<TipoImpresion>>(TipoImpresion.path, {}).toPromise().then(res => {
      this.tiposImpresion = res.data;
    });
  }

  back() {
    this.navigationService.back();
  }

  async confirmSave() {
    if (this.validateForm(this.producto)) {

        this.dialogService.confirm('Guardar precio',
        '¿Está seguro que desea guardar el precio del producto ' + this.producto.nombre + '?')
        .then( res => {
          if (res) {
            // this.dejarSoloUnTipoDeTalle(this.producto);
            this.save(res);
          }
        });
    }
  }

  validateForm(producto) {
    let validationsOK = false;
    this.submitted = true;
    this.error = false;
    // Valida el resto de los controles con sus validadores
    if (!this.formValidatorService.isFormValid(this.productoForm)) {
      validationsOK = false;
      this.validationFail = true;
    } else {
      validationsOK = true;
      this.validationFail = false;
    }
    if (this.validationFail && !this.error) {
      this.snackService.error('Hay datos inválidos. Por favor revise los campos marcados en rojo.');
    }

    return validationsOK;
    return true;
  }

  validarImagenes(prod: Producto) {
    if (prod.imagenes[0].file === undefined && !this.producto.id) {
      this.error = true;
      this.snackService.error('Debe cargar al menos una imágen.');
      return false;
    }

    if (prod.imagenes.some(i => !(i.colores && i.colores.length > 0) || !i.tipoImagen || !(i.file || i.name))) {
      return false;
    }

    return true;
  }

  async save(res) {

    if (res) {
      // if (this.tipoTalle === '3') {
      //   this.producto.talles = [];
      //   this.producto.tallesNumericos = [];
      // }
      this.snackService.spinner(true);

      // if (this.productoPropio !== 'false') {
      //   this.producto.cliente = null;
      // }

      // if (this.producto.id) { // Update
        try {
          this.producto.precio.idTipoMoneda = this.producto.precio.tipoMoneda.id;

          let clonePrecio = this.clonePrecioForBackend(this.producto.precio);

          await await this.backendService.update(Producto.path + 'precios/' + this.producto.id, clonePrecio);
          // this.saveImagenes(this.producto.id);
          // await this.delay(600);
          this.snackService.success('Precio guardado satisfactoriamente');
          this.router.navigate([Producto.path + 'detalles/' + this.producto.id]);
        } catch (ex) {
          if (ex[0]) {
            this.snackService.error(ex[0].details);
          } else if (ex.error.message) {
            this.snackService.error(ex.error.message);
          } else if (ex.errors) {
            this.snackService.error(ex.errors[0].defaultMessage);
          } else {
            this.snackService.error(ex.error.message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      // } else {
      //   try {
      //     const response = await this.backendService.createWithResponse(Producto.path, this.producto) as any;
      //     this.saveImagenes(response.id);
      //     this.router.navigate(['/productos']);
      //     this.snackService.success('Producto creado satisfactoriamente');
      //   } catch (ex) {
      //     if (ex[0]) {
      //       this.snackService.error(ex[0].details);
      //     } else if (ex.error.message) {
      //       this.snackService.error(ex.error.message);
      //     } else if (ex.errors) {
      //       this.snackService.error(ex.errors[0].defaultMessage);
      //     } else if (ex.error.details) {
      //       this.snackService.error(ex.error.details);
      //     } else {
      //       this.snackService.error(ex.error[0].details);
      //     }
      //   } finally {
      //     this.snackService.spinner(false);
      //   }
      // }
    }
  }
  async saveImagenes(productoId) {
    try {
      let formdata: FormData;
      const archivos = this.producto.imagenes;
      for (let i = 0; i < this.producto.imagenes.length; i++) {
        if (archivos[i].id === undefined && archivos[i].file !== null && archivos[i].file !== undefined) {
          formdata = new FormData();
          formdata.append('file', archivos[i].file);
          formdata.append('name', archivos[i].name);
          formdata.append('id', productoId);
          formdata.append('colorIds', archivos[i].colores.map(c => c.id).toString());
          formdata.append('tipoImagenId', archivos[i].tipoImagen.id.toString());
          await this.backendService.createFile(Producto.path + Imagen.path, formdata);
        }
      }
    } catch (ex) {
      this.snackService.error(ex.message);
    }
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }
  async seleccionarTodos() {
    await this.talles.subscribe(talles => {
      this.producto.talles = talles.data as Talle[];
    });
  }

  validarColores() {
    const usados = this.producto.imagenes.map(i => i.colores).reduce((a, b) => a.concat(b), []);
    const invalids = this.producto.colores.filter(c => !usados.find(u => u.id === c.id));

    return invalids.length === 0;
  }

  // onChangeEntidad() {
  //   if (this.producto.cliente.id) {
  //     this.listaClientes = this.clientes.filter(t => t.id === this.producto.cliente.id);
  //     const cliente = Object.assign({}, this.listaClientes[0]);
  //     this.producto.cliente = cliente;
  //   } else {
  //   this.producto.cliente.id = undefined;
  //   }
  // }

  private dejarSoloUnTipoDeTalle(producto: Producto) {
    if (this.tipoTalle === '1'){//numerico
       producto.talles = [];
    }else if (this.tipoTalle ==='2'){//letras
      producto.tallesNumericos = [];
    }
  }

  private deepCopy(obj) {

    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.deepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}
  public async updatePrecioFinal(event){

    // const ADICIONAL_COLOR = this.producto.precio.adicionalColor ? this.producto.precio.adicionalColor : 0;
    // const ADICIONAL_TALLE = this.producto.precio.adicionalTalle ? this.producto.precio.adicionalTalle : 0;
    // const PRECIO_BASE = this.producto.precio.precioBase ? this.producto.precio.precioBase : 0;


    // this.producto.precio.precioFinal = 1
      // PRECIO_BASE - PRECIO_BASE * ADICIONAL_COLOR / 100 - PRECIO_BASE * ADICIONAL_TALLE / 100;

      try {

        let clonePrecio = this.clonePrecioForBackend(this.producto.precio);

        const p =  await this.backendService.createWithResponse(Producto.path + 'precios/precio-final', clonePrecio) as any;
        this.producto.precio.precioFinal = p.precioFinal
        // this.saveImagenes(this.producto.id);
        // await this.delay(600);
        // this.snackService.success('Precio guardado satisfactoriamente');
        // this.router.navigate([Producto.path + 'detalles/' + this.producto.id]);
      } catch (ex) {
        if (ex[0]) {
          this.snackService.error(ex[0].details);
        } else if (ex.error.message) {
          this.snackService.error(ex.error.message);
        } else if (ex.errors) {
          this.snackService.error(ex.errors[0].defaultMessage);
        } else {
          this.snackService.error(ex.error.message);
        }
      } finally {
        // this.snackService.spinner(false);
      }
  }

  public clonePrecioForBackend(precio: Precio ): Precio{
    let clonePrecio = this.deepCopy(precio);

    if (precio.adicionalColor) {
      clonePrecio.adicionalColor = precio.adicionalColor / 100;
    }

    if (precio.adicionalTalle) {
      clonePrecio.adicionalTalle = precio.adicionalTalle / 100;
    }
    return clonePrecio;
  }
  public getSymbol(idMoneda){
    return TipoMoneda.getSymbol(idMoneda)
  }
}
