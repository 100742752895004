import { OperationalParametric } from './../../models/operationalParametric.model';
import { Precio } from './../../models/precio.model';
import { TipoMoneda } from './../../models/tipoMoneda.model';
import { Component, OnInit } from '@angular/core';
import { IImagenColor } from '../../models/i-imagen-color.model';
import { Observable, of } from '../../../../node_modules/rxjs';
import { CategoriaProducto } from '../../models/categoria-producto.model';
import { SubcategoriaProducto } from '../../models/subcategoria-producto.model';
import { Sugerencia } from '../../models/sugerencia.model';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';
import { DialogService } from '../../shared-components/modals/dialog.service';
import { SnackService } from '../../shared-services/snack.service';
import { BackendService } from '../../core/backend.service';
import { DomSanitizer } from '../../../../node_modules/@angular/platform-browser';
import { Producto } from '../../models/producto.model';
import { MatSelectChange } from '../../../../node_modules/@angular/material';

@Component({
  selector: 'app-producto-details',
  templateUrl: './producto-details.component.html',
  styleUrls: ['./producto-details.component.scss']
})
export class ProductoDetailsComponent implements OnInit {

  producto: any;
  imagen: any;
  imageUrlArray: IImagenColor[] = [{url: '', caption: '', href: '', colorIds: []}];
  images: Observable<IImagenColor[]>;
  primera = true;
  categoriasInternas: CategoriaProducto[];
  categoriasExternas: CategoriaProducto[];
  subcategoriasInternas: SubcategoriaProducto[];
  subcategoriasExternas: SubcategoriaProducto[];
  sugerenciasInternas: Sugerencia[];
  sugerenciasExternas: Sugerencia[];
  colorSelected = [];

  constructor(
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private snackService: SnackService,
    private backendService: BackendService,
    private sanitizer: DomSanitizer,
    public router: Router  ) { }

  ngOnInit() {
    this.images = of(this.imageUrlArray);
    this.route.data.subscribe(data => {
      this.producto = data.producto;
      this.producto.precio = this.producto.precio ? this.producto.precio : new Precio();
      this.producto.precio.tipoMoneda = this.producto.precio.tipoMoneda ? this.producto.precio.tipoMoneda : new OperationalParametric();
      data.producto.imagenes.forEach(e => {
        const colors = e.colores.map(c => c.id);
        if (e.path.indexOf('http') === 0) {
          this.imageUrlArray.push({url: encodeURI(e.path + e.name), caption: e.tipoImagen.description, colorIds: colors});
          if (this.primera) {
            this.imageUrlArray.splice(0, 1);
            this.primera = false;
          }
        } else {
          this.getImages(e.id, e.tipoImagen.description, colors);
        }
      });
    });
  }

  hayDatosExternos() {
    return (this.categoriasExternas && this.categoriasExternas.length > 0) ||
            (this.subcategoriasExternas && this.subcategoriasExternas.length > 0) ||
            (this.sugerenciasExternas && this.sugerenciasExternas.length > 0);
  }

  async getImages(id, desc, idsColor) {
    await this.backendService.getImagenById(Producto.path + 'imagen/' + id).subscribe(data => {
      const urlCreator = window.URL;
      this.imagen = this.sanitizer.bypassSecurityTrustUrl(urlCreator.createObjectURL(data));
      this.imageUrlArray.push({url: this.imagen.changingThisBreaksApplicationSecurity, caption: desc, colorIds: idsColor});
      if (this.primera) {
        this.imageUrlArray.splice(0, 1);
        this.primera = false;
      }
    });
  }

  editar(id: number) {
    this.router.navigate(['/productos/editar', id]);
  }

  precios(id: number) {
    this.router.navigate(['/productos/precios', id]);
  }

  tieneImagen() {
    return !(this.imageUrlArray.length === 1 && this.imageUrlArray[0].url === '');
  }

  async aceptarTodo() {
    this.dialogService.confirm('Validar datos externos',
    '¿Está seguro que desea aceptar todos los datos externos del producto?')
    .then( res => {
      if (res) {
        this.producto.categorias = this.producto.categoriasExternas;
        this.producto.subcategorias = this.producto.subcategoriasExternas;
        this.producto.sugerencias = this.producto.sugerenciasExternas;
        this.save(res, 'aceptar');
      }
    });
  }
  async descartarTodo() {
    this.dialogService.confirm('Validar datos externos',
    '¿Está seguro que desea rechazar todos los datos externos del producto?')
    .then( res => {
      if (res) {
        this.producto.categorias = [];
        this.save(res, 'descartar');
      }
    });
  }

  validarDatos() {
    this.router.navigate([Producto.path + 'validar']);
  }

  async save(res, operation) {
    if (res) {

      this.snackService.spinner(true);

      if (this.producto.id) { // Update
        try {
          await await this.backendService.update(Producto.path + 'validarproducto/' + this.producto.id + '/' + operation, null);
          this.snackService.success('Producto validado satisfactoriamente');
          this.router.navigate([Producto.path]);
        } catch (ex) {
          if (ex[0]) {
            this.snackService.error(ex[0].details);
          } else if (ex.errors) {
            this.snackService.error(ex.errors[0].defaultMessage);
          } else {
            this.snackService.error(ex.message);
          }
        } finally {
          this.snackService.spinner(false);
        }
      }
    }
  }

  colorChange(event: MatSelectChange) {
    const selection = Array.from(event.value).map(({ id }) => id);
    this.images = of(this.imageUrlArray.filter(i => i.colorIds.map(c => selection.indexOf(c)).filter(x => x >= 0).length > 0));
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  async confirmEliminar(id: number) {
    this.dialogService.confirm('Eliminar Producto',
    '¿Está seguro que desea eliminar este producto?')
    .then( res => {
      if (res) {
        this.deleteProducto(res, id);
      }
    });
  }
  async deleteProducto(res, id) {

    if (res) {

      this.snackService.spinner(true);

      try {
        await this.backendService.delete(Producto.path + 'eliminar/' + id);
        this.snackService.success('Producto eliminado correctamente');
        this.router.navigate(['/productos']);
      } catch (ex) {
        this.snackService.error(ex.message);
      } finally {
        this.snackService.spinner(false);
      }

    }
  }

  public getSymbol(idMoneda){
    return TipoMoneda.getSymbol(idMoneda)
  }

}
