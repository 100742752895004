import { OperationalParametric } from './operationalParametric.model';
export class Precio {

  static path = '/productos/precios/';

  id: number;
  precioBase: number;
  precioFinal: number;
  tipoMoneda: OperationalParametric;
  idTipoMoneda: number;
  adicionalColor: number;
  adicionalTalle: number;
  tallesNumericos: OperationalParametric[];
  colores: OperationalParametric[];
}
