import { CategoriaProducto } from './categoria-producto.model';
import { SubcategoriaProducto } from './subcategoria-producto.model';
import { Sugerencia } from './sugerencia.model';
import { Proveedor } from './proveedor.model';
import { Cliente } from './cliente.model';
import { OperationalParametric } from './operationalParametric.model';
import { Imagen } from './imagen.model';
import { MedidasProducto } from './medidas-producto.model';
import { Talle } from './talle.model';
import { TipoImpresion } from './tipo-impresion.model';
import { Tarifario } from './tarifario.model';
import { Entidad } from './entidad.model';
import { Precio } from './precio.model';

export class Producto {

    static path = '/productos/';
    static origenInterno = 'SMARTOFFICE';

    id: number;
    sku: string;
    nombre: string;
    detalle: string;
    categorias: CategoriaProducto[];
    subcategorias: SubcategoriaProducto[];
    sugerencias: Sugerencia[];
    proveedor: Proveedor;
    descripcion: string;
    imagenes: Imagen[];
    activo: string;
    colores: OperationalParametric[];
    medidas: MedidasProducto;
    talles: Talle[];
    tallesNumericos: OperationalParametric[];
    tiposImpresion: TipoImpresion[];
    tarifario: Tarifario;
    cliente: Cliente;
    precio: Precio;


    get categoriasInternas() {
        return this.categorias.filter(s => s.origen === Producto.origenInterno);
    }
}
