import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from '../core/core.module';
import { VendorModule } from '../core/vendor.module';
import { SharedComponentsModule } from '../shared-components/shared-components.module';
import { SharedServicesModule } from '../shared-services/shared-services.module';
import { SecurityModule } from '../security/security.module';
import { ProductosComponent } from './productos.component';
import { SecurityService } from '../security/security.service';
import { LoguedGuardService } from '../security/logued-guard.service';
import { ProductoTableComponent } from './producto-table/producto-table.component';
import { ProductoDetailsComponent } from './producto-details/producto-details.component';
import { ProductoResolver } from './producto.resolver';
import { EscapeHtmlPipe } from '../shared-services/keep-html.pipe';
import {SlideshowModule} from 'ng-simple-slideshow';
import { ProductoAddEditComponent } from './producto-add-edit/producto-add-edit.component';
import { ProductoImagenesUploadComponent } from './producto-imagenes-upload/producto-imagenes-upload.component';
import { CategoriaProductoComponent } from './categoria-producto/categoria-producto.component';
import { CategoriaProductoTableComponent } from './categoria-producto/categoria-producto-table/categoria-producto-table.component';
import { CategoriaProductoAddEditComponent } from './categoria-producto/categoria-producto-add-edit/categoria-producto-add-edit.component';
import { CategoriaProductoResolver } from './categoria-producto/categoria-producto.resolver';
import { SubcategoriaProductoComponent } from './subcategoria-producto/subcategoria-producto.component';
import { SubcategoriaProductoTableComponent } from './subcategoria-producto/subcategoria-producto-table/subcategoria-producto-table.component';
import { SubcategoriaProductoAddEditComponent } from './subcategoria-producto/subcategoria-producto-add-edit/subcategoria-producto-add-edit.component';
import { SubcategoriaProductoResolver } from './subcategoria-producto/subcategoria-producto.resolver';
import { TalleComponent } from './talle/talle.component';
import { TalleTableComponent } from './talle/talle-table/talle-table.component';
import { TalleAddEditComponent } from './talle/talle-add-edit/talle-add-edit.component';
import { TalleResolver } from './talle/talle.resolver';
import { ColorComponent } from './color/color.component';
import { ColorTableComponent } from './color/color-table/color-table.component';
import { ColorAddEditComponent } from './color/color-add-edit/color-add-edit.component';
import { ColorResolver } from './color/color.resolver';
import { ColorPickerModule } from 'ngx-color-picker';
import { EntidadComponent } from './entidad/entidad.component';
import { EntidadTableComponent } from './entidad/entidad-table/entidad-table.component';
import { EntidadAddEditComponent } from './entidad/entidad-add-edit/entidad-add-edit.component';
import { EntidadResolver } from './entidad/entidad.resolver';
import {CKEditorModule} from "ng2-ckeditor";
import { ProductoPreciosComponent } from './producto-precios/producto-precios.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    CoreModule,
    VendorModule,
    SharedComponentsModule,
    SharedServicesModule,
    SecurityModule,
    SlideshowModule,
    ColorPickerModule,
    CKEditorModule
  ],
    declarations: [
        ProductosComponent,
        ProductoTableComponent,
        ProductoDetailsComponent,
        EscapeHtmlPipe,
        ProductoAddEditComponent,
        ProductoPreciosComponent,
        ProductoImagenesUploadComponent,
        CategoriaProductoComponent,
        CategoriaProductoTableComponent,
        CategoriaProductoAddEditComponent,
        SubcategoriaProductoComponent,
        SubcategoriaProductoTableComponent,
        SubcategoriaProductoAddEditComponent,
        TalleComponent,
        TalleTableComponent,
        TalleAddEditComponent,
        ColorComponent,
        ColorTableComponent,
        ColorAddEditComponent,
        EntidadComponent,
        EntidadTableComponent,
        EntidadAddEditComponent
    ],
    exports: [
        ProductosComponent,
        ProductoTableComponent,
        ProductoDetailsComponent,
        EscapeHtmlPipe,
        ProductoAddEditComponent,
        ProductoImagenesUploadComponent,
        CategoriaProductoComponent,
        CategoriaProductoTableComponent,
        SubcategoriaProductoComponent,
        SubcategoriaProductoTableComponent,
        SubcategoriaProductoAddEditComponent,
        TalleComponent,
        TalleTableComponent,
        TalleAddEditComponent,
        ColorTableComponent,
        EntidadComponent,
        EntidadTableComponent,
        EntidadAddEditComponent
    ],
    providers: [
        SecurityService,
        LoguedGuardService,
        ProductoResolver,
        CategoriaProductoResolver,
        SubcategoriaProductoResolver,
        TalleResolver,
        ColorResolver,
        EntidadResolver
    ]
    })
export class ProductosModule { }
