import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './security/login/login.component';
import { LayoutComponent } from './shared-components/layout/layout.component';
import { LoguedGuardService } from './security/logued-guard.service';
import { EmptyHomeComponent } from './home/empty-home/empty-home.component';
import { RedirectGuard } from './redirect-guard.service';
import { UserComponent } from './security/user/user.component';
import { UserTableComponent } from './security/user/user-table/user-table.component';
import { UserAddEditComponent } from './security/user/user-add-edit/user-add-edit.component';
import { UserResolver } from './security/user/user.resolver';
import { UserChangePasswordComponent } from './security/user/user-change-password/user-change-password.component';
import { UserChangePasswordResolver } from './security/user/user-change-password/user-change-password.resolver';
import { RoleComponent } from './security/role/role.component';
import { RoleTableComponent } from './security/role/role-table/role-table.component';
import { RoleAddEditComponent } from './security/role/role-add-edit/role-add-edit.component';
import { RoleResolver } from './security/role/role.resolver';
import { PermissionComponent } from './security/permission/permission.component';
import { ClientesComponent } from './clientes/clientes.component';
import { ClienteTableComponent } from './clientes/cliente-table/cliente-table.component';
import { ClienteDetailsComponent } from './clientes/cliente-details/cliente-details.component';
import { ClienteResolver } from './clientes/cliente.resolver';
import { ClienteAddEditComponent } from './clientes/cliente-add-edit/cliente-add-edit.component';
import { ProductosComponent } from './productos/productos.component';
import { ProductoTableComponent } from './productos/producto-table/producto-table.component';
import { ProductoDetailsComponent } from './productos/producto-details/producto-details.component';
import { ProductoResolver } from './productos/producto.resolver';
import { ProductoAddEditComponent } from './productos/producto-add-edit/producto-add-edit.component';
import { CategoriaProductoComponent } from './productos/categoria-producto/categoria-producto.component';
import { CategoriaProductoTableComponent } from './productos/categoria-producto/categoria-producto-table/categoria-producto-table.component';
import { CategoriaProductoAddEditComponent } from './productos/categoria-producto/categoria-producto-add-edit/categoria-producto-add-edit.component';
import { CategoriaProductoResolver } from './productos/categoria-producto/categoria-producto.resolver';
import { SubcategoriaProductoComponent } from './productos/subcategoria-producto/subcategoria-producto.component';
import { SubcategoriaProductoTableComponent } from './productos/subcategoria-producto/subcategoria-producto-table/subcategoria-producto-table.component';
import { SubcategoriaProductoAddEditComponent } from './productos/subcategoria-producto/subcategoria-producto-add-edit/subcategoria-producto-add-edit.component';
import { SubcategoriaProductoResolver } from './productos/subcategoria-producto/subcategoria-producto.resolver';
import { TalleComponent } from './productos/talle/talle.component';
import { TalleTableComponent } from './productos/talle/talle-table/talle-table.component';
import { TalleAddEditComponent } from './productos/talle/talle-add-edit/talle-add-edit.component';
import { TalleResolver } from './productos/talle/talle.resolver';
import { ColorComponent } from './productos/color/color.component';
import { ColorTableComponent } from './productos/color/color-table/color-table.component';
import { ColorAddEditComponent } from './productos/color/color-add-edit/color-add-edit.component';
import { ColorResolver } from './productos/color/color.resolver';
import { CotizacionComponent } from './configuracion/cotizacion/cotizacion.component';
import { CotizacionTableComponent } from './configuracion/cotizacion/cotizacion-table/cotizacion-table.component';
import { CotizacionEditComponent } from './configuracion/cotizacion/cotizacion-edit/cotizacion-edit.component';
import { CotizacionResolver } from './configuracion/cotizacion/cotizacion.resolver';
import { TiposPersonasComponent } from './clientes/tipos-personas/tipos-personas.component';
import { TiposPersonasTableComponent } from './clientes/tipos-personas/tipos-personas-table/tipos-personas-table.component';
import { TiposServicioComponent } from './clientes/tipos-servicio/tipos-servicio.component';
import { TiposServicioTableComponent } from './clientes/tipos-servicio/tipos-servicio-table/tipos-servicio-table.component';
import { TiposPersonasAddEditComponent } from './clientes/tipos-personas/tipos-personas-add-edit/tipos-personas-add-edit.component';
import { TiposPersonasResolver } from './clientes/tipos-personas/tipos-personas.resolver';
import { TiposServicioAddEditComponent } from './clientes/tipos-servicio/tipos-servicio-add-edit/tipos-servicio-add-edit.component';
import { TiposServicioResolver } from './clientes/tipos-servicio/tipos-servicio.resolver';
import { EntidadComponent } from './productos/entidad/entidad.component';
import { EntidadTableComponent } from './productos/entidad/entidad-table/entidad-table.component';
import { EntidadAddEditComponent } from './productos/entidad/entidad-add-edit/entidad-add-edit.component';
import { EntidadResolver } from './productos/entidad/entidad.resolver';
import { ComprobantesComponent } from './cobranzas-pagos/comprobantes/comprobantes.component';
import { ComprobanteTableComponent } from './cobranzas-pagos/comprobantes/comprobante-table/comprobante-table.component';
import { ComprobanteAddEditComponent } from './cobranzas-pagos/comprobantes/comprobante-add-edit/comprobante-add-edit.component';
import { ComprobanteResolver } from './cobranzas-pagos/comprobantes/comprobantes.resolver';
import { SucursalComponent } from './security/sucursal/sucursal.component';
import { SucursalTableComponent } from './security/sucursal/sucursal-table/sucursal-table.component';
import { SucursalAddEditComponent } from './security/sucursal/sucursal-add-edit/sucursal-add-edit.component';
import { SucursalResolver } from './security/sucursal/sucursal.resolver';
import { MediosPagoComponent } from './cobranzas-pagos/medios-pago/medios-pago.component';
import { MedioPagoTableComponent } from './cobranzas-pagos/medios-pago/medio-pago-table/medio-pago-table.component';
import { MedioPagoAddEditComponent } from './cobranzas-pagos/medios-pago/medio-pago-add-edit/medio-pago-add-edit.component';
import { MediosPagoResolver } from './cobranzas-pagos/medios-pago/medios-pago.resolver';
import { OperacionesComponent } from './cobranzas-pagos/operaciones/operaciones.component';
import { OperacionTableComponent } from './cobranzas-pagos/operaciones/operacion-table/operacion-table.component';
import { OperacionAddEditComponent } from './cobranzas-pagos/operaciones/operacion-add-edit/operacion-add-edit.component';
import { OperacionResolver } from './cobranzas-pagos/operaciones/operaciones.resolver';
import { EntidadOperacionComponent } from './cobranzas-pagos/entidad-operacion/entidad-operacion.component';
import { EntidadOperacionTableComponent } from './cobranzas-pagos/entidad-operacion/entidad-operacion-table/entidad-operacion-table.component';
import { CuentaCorrienteComponent } from './clientes/cuenta-corriente/cuenta-corriente.component';
import { CuentaCorrienteViewComponent } from './clientes/cuenta-corriente/cuenta-corriente-view/cuenta-corriente-view.component';
import { ProductoPreciosComponent } from './productos/producto-precios/producto-precios.component';


const routes: Routes = [
  { path: 'login', component: LoginComponent },
  {
    path: '', component: LayoutComponent,
    canActivate: [LoguedGuardService], canActivateChild: [LoguedGuardService],
    children: [
      {
        path: 'home', component: EmptyHomeComponent,
        data: { title: 'Home', profile: true },
      },
      {
        path: 'permisos', component: PermissionComponent,
        data: { title: 'Permisos', profile: true, expectedPermission: 'VISUALIZAR_PERMISOS' }
      },
      {
        path: 'roles', component: RoleComponent,
        data: { title: 'Roles' },
        children: [{
          path: '', component: RoleTableComponent,
          data: { expectedPermission: 'VISUALIZAR_ROLES' },
        },
        {
          path: 'agregar', component: RoleAddEditComponent,
          data: { expectedPermission: 'CREAR_ROL' }, resolve: { role: RoleResolver }
        },
        {
          path: 'editar/:id', component: RoleAddEditComponent,
          data: { expectedPermission: 'EDITAR_ROL' }, resolve: { role: RoleResolver }
        }]
      },
      {
        path: 'usuarios', component: UserComponent,
        data: { title: 'Usuarios', },
        children: [{
          path: '', component: UserTableComponent,
          data: { expectedPermission: 'VISUALIZAR_USUARIOS' },
        },
        {
          path: 'agregar', component: UserAddEditComponent,
          data: { expectedPermission: 'CREAR_USUARIO' }, resolve: { user: UserResolver }
        },
        {
          path: 'editar/:id', component: UserAddEditComponent,
          data: { expectedPermission: 'EDITAR_USUARIO' }, resolve: { user: UserResolver }
        },
        {
          path: 'cambiar_password/:id', component: UserChangePasswordComponent,
          data: { expectedPermission: 'CAMBIAR_CONTRASENIA_USUARIO' }, resolve: { user: UserChangePasswordResolver }
        },
        {
          path: 'cambiar_mi_password', component: UserChangePasswordComponent,
          data: { expectedPermission: 'MI_PERFIL' }, resolve: { user: UserChangePasswordResolver }
        }]
      },
      {
        path: 'clientes', component: ClientesComponent,
        data: { title: 'Clientes' },
        children: [{
          path: '', component: ClienteTableComponent,
          data: { title: 'Clientes', expectedPermission: 'VISUALIZAR_CLIENTES' }
        },
        {
          path: 'detalles/:id', component: ClienteDetailsComponent,
          data: { title: 'Detalle del cliente', expectedPermission: 'VISUALIZAR_CLIENTES' }, resolve: { cliente: ClienteResolver }
        },
        {
          path: 'editar/:id', component: ClienteAddEditComponent,
          data: { title: 'Editar cliente', expectedPermission: 'VISUALIZAR_CLIENTES' }, resolve: { cliente: ClienteResolver }
        },
        {
          path: 'agregar', component: ClienteAddEditComponent,
          data: { title: 'Agregar cliente', expectedPermission: 'VISUALIZAR_CLIENTES' }, resolve: { cliente: ClienteResolver }
        },/*
        {
          path: 'agregar-usuario/:id', component: ClienteUserAddEditComponent,
          // tslint:disable-next-line:max-line-length
          data: { title: 'Agregar usuario del cliente', expectedPermission: 'VISUALIZAR_CLIENTES' }, resolve: { cliente: UsuarioClienteResolver }
        },
        {
          path: 'editar-usuario/:id', component: ClienteUserAddEditComponent,
          // tslint:disable-next-line:max-line-length
          data: { title: 'Editar usuario del cliente', expectedPermission: 'VISUALIZAR_CLIENTES' }, resolve: { cliente: UsuarioClienteResolver }
        },
        {
          path: ':id/monto-max-usuarios', component: ClienteUserMontoMaxComponent,
          // tslint:disable-next-line:max-line-length
          data: { title: 'Cambiar tipo y monto max del cliente', expectedPermission: 'VISUALIZAR_CLIENTES' }, resolve: { cliente: ClienteResolver }
        },
        {
          path: 'validar-documentacion/:id', component: ValidarDocumentacionComponent,
          data: { title: 'Validar documentación', expectedPermission: 'VISUALIZAR_CLIENTES' },
           resolve: { validarDocumentacion: AprobacionClienteResolver }
        },
        {
          path: 'pendientes_aprobacion', component: ClientesPendientesAprobacionListComponent,
          data: { title: 'Clientes Pendientes de Aprobación', expectedPermission: 'VISUALIZAR_CLIENTES' }
        }*/]
      },
      {
        path: 'productos', component: ProductosComponent,
        data: { title: 'Productos' },
        children: [{
          path: '', component: ProductoTableComponent,
          data: { expectedPermission: 'VISUALIZAR_PRODUCTOS' },
        },
        {
          path: 'detalles/:id', component: ProductoDetailsComponent,
          data: { title: 'Detalle del Producto', expectedPermission: 'VISUALIZAR_PRODUCTO' }, resolve: { producto: ProductoResolver }
        },
        {
          path: 'agregar', component: ProductoAddEditComponent,
          data: { expectedPermission: 'CREAR_PRODUCTO' }, resolve: { user: ProductoResolver }
        },
        {
          path: 'editar/:id', component: ProductoAddEditComponent,
          data: { expectedPermission: 'EDITAR_PRODUCTO' }, resolve: { user: ProductoResolver }
        },
        {
          path: 'precios/:id', component: ProductoPreciosComponent,
          data: { expectedPermission: 'EDITAR_PRODUCTO' }, resolve: { user: ProductoResolver }
        },/*
        {
          path: 'validar', component: ProductoDValidarDatosComponent,
          data: { expectedPermission: 'EDITAR_PRODUCTO' }, resolve: { user: ProductoResolver }
        },
        {
          path: 'pedidos/:id', component: CantidadTotalComponent,
          data: { expectedPermission: 'VISUALIZAR_PRODUCTOS' }, resolve: { user: ProductoResolver }
        }*/
      ]
      },
      {
        path: 'categorias', component: CategoriaProductoComponent,
        data: { title: 'Categorías de Productos', },
        children: [{
          path: '', component: CategoriaProductoTableComponent,
          data: { expectedPermission: 'VISUALIZAR_CATEGORIAS_PRODUCTO' },
        },
        {
          path: 'agregar', component: CategoriaProductoAddEditComponent,
          data: { expectedPermission: 'CREAR_CATEGORIA_PRODUCTO' }, resolve: { user: CategoriaProductoResolver }
        },
        {
          path: 'editar/:id', component: CategoriaProductoAddEditComponent,
          data: { expectedPermission: 'EDITAR_CATEGORIA_PRODUCTO' }, resolve: { user: CategoriaProductoResolver }
        }]
      },
      {
        path: 'subcategorias', component: SubcategoriaProductoComponent,
        data: { title: 'Subcategorías de Productos', },
        children: [{
          path: '', component: SubcategoriaProductoTableComponent,
          data: { expectedPermission: 'VISUALIZAR_SUBCATEGORIAS_PRODUCTO' },
        },
        {
          path: 'agregar', component: SubcategoriaProductoAddEditComponent,
          data: { expectedPermission: 'CREAR_SUBCATEGORIA_PRODUCTO' }, resolve: { user: SubcategoriaProductoResolver }
        },
        {
          path: 'editar/:id', component: SubcategoriaProductoAddEditComponent,
          data: { expectedPermission: 'EDITAR_SUBCATEGORIA_PRODUCTO' }, resolve: { user: SubcategoriaProductoResolver }
        }]
      },
      {
        path: 'talles', component: TalleComponent,
        data: { title: 'Talles', },
        children: [{
          path: '', component: TalleTableComponent,
          data: { expectedPermission: 'VISUALIZAR_TALLES' },
        },
        {
          path: 'agregar', component: TalleAddEditComponent,
          data: { expectedPermission: 'CREAR_TALLE' }, resolve: { user: TalleResolver }
        },
        {
          path: 'editar/:id', component: TalleAddEditComponent,
          data: { expectedPermission: 'EDITAR_TALLE' }, resolve: { user: TalleResolver }
        }]
      },
      {
        path: 'colores', component: ColorComponent,
        data: { title: 'Colores', },
        children: [{
          path: '', component: ColorTableComponent,
          data: { expectedPermission: 'VISUALIZAR_COLORES' },
        },
        {
          path: 'agregar', component: ColorAddEditComponent,
          data: { expectedPermission: 'CREAR_COLOR' }, resolve: { user: ColorResolver }
        },
        {
          path: 'editar/:id', component: ColorAddEditComponent,
          data: { expectedPermission: 'EDITAR_COLOR' }, resolve: { user: ColorResolver }
        }]
      },
      {
        path: 'cotizacion', component: CotizacionComponent,
        data: { title: 'Cotizacion' },
        children: [{
          path: '', component: CotizacionTableComponent,
          data: { expectedPermission: 'MODIFICAR_COTIZACION_DOLAR_PESO' },
        },
        {
          path: ':id', component: CotizacionEditComponent,
          data: { expectedPermission: 'MODIFICAR_COTIZACION_DOLAR_PESO' }, resolve: { cotizacion: CotizacionResolver }
        }
        ]
      },
      {
        path: 'tipo-persona', component: TiposPersonasComponent,
        data: { title: 'Tipo de Personas', },
        children: [{
          path: '', component: TiposPersonasTableComponent,
          data: { expectedPermission: 'VISUALIZAR_TIPO_PERSONA' },
        },
        {
          path: 'agregar', component: TiposPersonasAddEditComponent,
          data: { expectedPermission: 'CREAR_TIPO_PERSONA' }, resolve: { user: TiposPersonasResolver }
        },
        {
          path: 'editar/:id', component: TiposPersonasAddEditComponent,
          data: { expectedPermission: 'EDITAR_TIPO_PERSONA' }, resolve: { user: TiposPersonasResolver }
        }]
      },
      {
        path: 'tipo-servicio', component: TiposServicioComponent,
        data: { title: 'Tipo de Servicios', },
        children: [{
          path: '', component: TiposServicioTableComponent,
          data: { expectedPermission: 'VISUALIZAR_TIPO_SERVICIO' },
        },
        {
          path: 'agregar', component: TiposServicioAddEditComponent,
          data: { expectedPermission: 'CREAR_TIPO_SERVICIO' }, resolve: { user: TiposServicioResolver }
        },
        {
          path: 'editar/:id', component: TiposServicioAddEditComponent,
          data: { expectedPermission: 'EDITAR_TIPO_SERVICIO' }, resolve: { user: TiposServicioResolver }
        }]
      },
      {
        path: 'entidades', component: EntidadComponent,
        data: { title: 'Entidad', },
        children: [{
          path: '', component: EntidadTableComponent,
          data: { expectedPermission: 'VISUALIZAR_ENTIDAD' },
        },
        {
          path: 'agregar', component: EntidadAddEditComponent,
          data: { expectedPermission: 'CREAR_ENTIDAD' }, resolve: { user: EntidadResolver }
        },
        {
          path: 'editar/:id', component: EntidadAddEditComponent,
          data: { expectedPermission: 'EDITAR_ENTIDAD' }, resolve: { user: EntidadResolver }
        }]
      },
      {
        path: 'comprobante', component: ComprobantesComponent,
        data: { title: 'Comprobante', },
        children: [{
          path: '', component: ComprobanteTableComponent,
          data: { expectedPermission: 'VISUALIZAR_COMPROBANTE' },
        },
        {
          path: 'agregar', component: ComprobanteAddEditComponent,
          data: { expectedPermission: 'CREAR_COMPROBANTE' }, resolve: { comprobante: ComprobanteResolver }
        },
        {
          path: 'editar/:id', component: ComprobanteAddEditComponent,
          data: { expectedPermission: 'EDITAR_COMPROBANTE' }, resolve: { comprobante: ComprobanteResolver }
        }]
      },
      {
        path: 'sucursales', component: SucursalComponent,
        data: { title: 'Sucursal', },
        children: [{
          path: '', component: SucursalTableComponent,
          data: { expectedPermission: 'VISUALIZAR_SUCURSAL' },
        },
        {
          path: 'agregar', component: SucursalAddEditComponent,
          data: { expectedPermission: 'CREAR_SUCURSAL' }, resolve: { sucursal: SucursalResolver }
        },
        {
          path: 'editar/:id', component: SucursalAddEditComponent,
          data: { expectedPermission: 'EDITAR_SUCURSAL' }, resolve: { sucursal: SucursalResolver }
        }]
      },
      {
        path: 'medio-pago', component: MediosPagoComponent,
        data: { title: 'Medio de pago', },
        children: [{
          path: '', component: MedioPagoTableComponent,
          data: { expectedPermission: 'VISUALIZAR_MEDIO_PAGO' },
        },
        {
          path: 'agregar', component: MedioPagoAddEditComponent,
          data: { expectedPermission: 'CREAR_MEDIO_PAGO' }, resolve: { medioPago: MediosPagoResolver }
        },
        {
          path: 'editar/:id', component: MedioPagoAddEditComponent,
          data: { expectedPermission: 'EDITAR_MEDIO_PAGO' }, resolve: { medioPago: MediosPagoResolver }
        }]
      },
      {
        path: 'operaciones', component: EntidadOperacionComponent,
        data: { title: 'Entidades', },
        children: [/*{
          path: '', component: EntidadOperacionTableComponent,
          data: { expectedPermission: 'VISUALIZAR_OPERACION' },
        },*/
        {
          path: ':id', component: OperacionTableComponent,
          data: { expectedPermission: 'VISUALIZAR_OPERACION' }
        },
        {
          path: ':id/agregar', component: CuentaCorrienteViewComponent,
          data: { expectedPermission: 'CREAR_OPERACION' }, resolve: { cliente: ClienteResolver }
        },
        {
          path: ':id/editar/:opId', component: CuentaCorrienteViewComponent,
          data: { expectedPermission: 'EDITAR_OPERACION' }, resolve: { cliente: ClienteResolver, operacionVista: OperacionResolver }
        }]
      },
      /*{
        path: 'cuenta-corriente', component: CuentaCorrienteComponent,
        data: { title: 'Cuenta corriente', },
        children: [{
          path: ':id', component: CuentaCorrienteViewComponent,
          data: { expectedPermission: 'VISUALIZAR_OPERACION' }, resolve: { cliente: ClienteResolver }
        }]
      },*/
      { path: '**', redirectTo: '', canActivate: [RedirectGuard] },
    ]
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    RedirectGuard
  ]
})
export class AppRoutingModule { }
