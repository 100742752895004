import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Filters } from '../../models/filters.model';
import { SearchInputComponent } from '../../shared-components/search-input/search-input.component';
import { Subject, Subscription, Observer, Observable } from 'rxjs';
import { MediaService } from '../../shared-services/media.service';
import { UpdateEventService } from '../../shared-services/update-event.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatPaginatorIntl } from '@angular/material';
import { SnackService } from '../../shared-services/snack.service';
import { DialogService } from '../../shared-components/modals/dialog.service';
import { BackendService } from '../../core/backend.service';
import { switchMap, share } from 'rxjs/operators';
import { PaginatedList } from '../../models/paginatedList.model';
import { Producto } from '../../models/producto.model';

@Component({
  selector: 'app-producto-table',
  templateUrl: './producto-table.component.html',
  styleUrls: ['./producto-table.component.css']
})
export class ProductoTableComponent implements OnInit {

  public filters: Filters = {
    page: 0,
    size: 50,
    sort: 'created,desc'
  };

  public showFilters = true;
  isMobile: boolean;

  @ViewChild(SearchInputComponent, {static: false})
  private searchComponent: SearchInputComponent;

  private getNewPage = new Subject<Filters>();

  public totalItems: number;

  roleFiltersActive = false;
  mostrarResumen = false;
  isLoading = false;

  filtersLoaded = false;
  subscriptions: Subscription[] = [];

  addUrl = '/productos/agregar';
  addText = 'Agregar Producto';
  searchTitle = 'Productos';
  searchPlaceHolder = 'Ingrese el nombre del producto que desea buscar';
  messageEmpty = 'No se encuentran productos.';
  messageFilters = 'No se encuentran productos que coincidan con el criterio de búsqueda actual.';
  botonAgregar = true;
  permisoCrear = 'VISUALIZAR_PRODUCTO';
  productosLista = [];
  atributoBusqueda: string = 'textsearch';

  productosList = this.backendService.newSubjectWithoutDistinct<Filters>()
  .pipe(switchMap(filters => {
    const usersPromise = this.backendService.get<PaginatedList<Producto>>(Producto.path, filters).toPromise();
    return (async () => {
      this.isLoading = true;
      try {
        const permissions = await usersPromise;
        this.productosLista = permissions.data;
        return permissions;
      } catch (error) {
        return [];
      } finally {
        this.isLoading = false;
        this.roleFiltersActive = !(this.filters.search === '' || this.filters.search === undefined);
      }
    })();
  })).pipe(share()) as Observer<Filters> & Observable<Producto[]>;

  constructor(
    private backendService: BackendService,
    private dialogService: DialogService,
    private snackService: SnackService,
    public router: Router,
    protected updateEventService: UpdateEventService,
    private mediaService: MediaService,
  ) { }

  async ngOnInit() {
    this.subscriptions.push(this.mediaService.isMobile.subscribe(isMobile => {
      this.isMobile = isMobile;
      this.showFilters = !this.isMobile;
    }));

    this.refreshRoleList();
  }

  refreshRoleList() {
    this.productosList.next(this.getFilters());
  }

  getDefaultFilters() {
    this.filters = {
      page: 0,
      size: 50,
      sort: 'created,desc'
    };
  }

  getFilters() {
    return this.filters;
  }

  detallesProducto(id: number) {
    this.router.navigate(['/productos/detalles', id]);
  }

  redireccion(id: number) {
    this.router.navigate(['/productos/pedidos', id]);
  }

  async confirmEliminar(id: number) {
    this.dialogService.confirm('Eliminar Producto',
    '¿Está seguro que desea eliminar este producto?')
    .then( res => {
      if (res) {
        this.deleteProducto(res, id);
      }
    });
  }

  async deleteProducto(res, id) {

    if (res) {

      this.snackService.spinner(true);

      try {
        await this.backendService.delete(Producto.path + 'eliminar/' + id);
        this.snackService.success('Producto eliminado correctamente');
        this.router.navigate(['/productos']);
      } catch (ex) {
        this.snackService.error(ex.error.message);
      } finally {
        this.snackService.spinner(false);
      }

    }
  }

}
