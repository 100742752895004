export class TipoMoneda {

    static path = '/parametricas/tipos_monedas/no_cripto/';
    id: number;
    simbolo: string;

    public static getSymbol(idMoneda){
      if (idMoneda) {
        switch (idMoneda) {
          case 1:
            return "USD";
          case 2:
            return "ARS";
          default:
            return "Symbol not Defined";
        }
      }
    }
}
